/**
 * @module Predictive Search API
 * @description Our zipcode / address API
 */
import { useContext } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import DOMPurify from 'isomorphic-dompurify';

import { FuelContext } from '@/providers/fuel-provider';
import { onlySpaces } from '@/js/utils/filters';
import { trackEvent } from '@/js/helpers/tracking';
import { setCookie } from '@/js/utils/cookie';
import { addPageAction } from '@/js/utils/new-relic';
import useCartState from './useCartState';
import { persistData } from '@/js/utils/session-storage';
import { stripClickThrough } from '@/js/helpers/cart';

/**
 * @function usePredictiveSearch
 * @description
 */
export const usePredictiveSearch = ( refs, setSuggestions, formContext ) => {
  const { clickThrough, olVersion, gdtVersion } = useContext( FuelContext );
  const { cartStore, submitDifferentAddress } = useCartState();

  /**
   * @function buildAddress
   * @description Build the address
   */
  const buildAddress = ( suggestion ) => {
    let whiteSpace = ' ';
    if ( suggestion.secondary ) {
      if ( suggestion.entries > 1 ) {
        suggestion.secondary += ` (${ suggestion.entries } entries)`;
      }
      whiteSpace = ' ';
    }
    return `${ suggestion.addressLine1 }${ whiteSpace }${ suggestion.addressLine2 } ${ suggestion.city }, ${ suggestion.stateProvince } ${ suggestion.zipCode }`;
  };

  /**
   * @function handleInput
   * @description track our events
   */
  const handleInput = ( event ) => {
    const { target } = event;
    const { fieldName, fieldType, fieldValue } = JSON.parse( target.getAttribute( 'data-payload' ) );

    const data = {
      formContext,
      fieldName,
      fieldType,
      fieldValue
    };

    // need to wait for formstarted to run
    setTimeout( () => {
      trackEvent( { action: 'fieldInputted', data, event } );
    }, 100 );
  };

  /**
   * @function handleKeyDown
   * @description Handle a keydown event
   */
  const handleKeyDownEvent = ( event ) => {
    // handle escape key
    if ( event.key === 'Escape' ) {
      setSuggestions( [ ] );
      return refs.addressField.current.removeEventListener( 'keydown', handleKeyDownEvent );
    }
  };

  /**
   * @function handleClickEvent
   * @description Handle a keydown event
   */
  const handleClickEvent = ( event ) => {
    const { target } = event;

    if ( target.hasAttribute( 'data-address' ) ) {
      return;
    }

    setSuggestions( [ ] );
  };

  /**
   * @function fetchAddressSuggestions
   * @description Fetch the address
   */
  const fetchAddressSuggestions = async ( value, selected ) => {
    if ( ! value || onlySpaces( value ) ) {
      return;
    }

    const url = new URL( `${ process.env.predictiveSearchUrl }/api/${ olVersion }/serviceability/predictive` );
    const params = url.searchParams;

    params.set( 'inFootPrint', false );
    params.set( 'serviceAddressRecord', false );
    params.set( 'excludeChildren', false );

    if ( selected !== '' ) {
      params.set( 'selected', selected );
    } else if ( params.has( 'selected' ) ) {
      params.delete( 'selected' );
    }

    params.set( 'address', value );

    const addresses = await fetch( url )
      .then( ( res ) => res.json() )
      .then( ( data ) => {
        if ( ! data ) {
          return [];
        }

        const suggestions = [];

        if ( ! data.length ) {
          return suggestions;
        }

        if ( data === null || typeof data === 'undefined' ) {
          suggestions.push( { } );
          return suggestions;
        }

        Object.values( data ).forEach( ( suggestion ) => {
          const address = {
            value: buildAddress( suggestion ),
            addressKey: suggestion.addressKey,
            addressLine1: suggestion.addressLine1,
            addressLine2: suggestion.addressLine2,
            city: suggestion.city,
            stateProvince: suggestion.stateProvince,
            zipCode: suggestion.zipCode,
            zipCode4: suggestion.zipCode4,
            latitude: suggestion.latitude,
            longitude: suggestion.longitude,
            parsedAddress: suggestion.parsedAddress
          };

          suggestions.push( address );
        } );

        return suggestions;
      } );

    return addresses;
  };

  /**
   * @function addressAsciiSanitization
   * @description Remove non-ascii characters and replace special characters with their regular counterpart
   */
  const addressAsciiSanitization = ( address ) => address.normalize( 'NFD' ).replace( /\u00A0/g, ' ' ).replace( /([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '' );

  /**
   * @function fetchAddress
   * @description Fetch our address suggestions
   */
  const fetchAddress = async ( event ) => {
    const { target } = event;
    const { value } = target;

    handleInput( event );

    if ( ! value || value.length < 3 ) {
      setSuggestions( [ ] );
      return;
    }

    const suggestionValues = await fetchAddressSuggestions( addressAsciiSanitization( DOMPurify.sanitize( value ) ), '' );

    setSuggestions( suggestionValues || [ ] );

    // register keydown listeners once address suggestions populate
    refs.addressField.current.addEventListener( 'keydown', handleKeyDownEvent );
    document.addEventListener( 'click', handleClickEvent );
  };

  /**
   * @function setSuggestionValue
   * @description Set suggestion value on address and zip
   */
  const setSuggestionValue = async ( event ) => {
    const { target } = event;

    const address = target.getAttribute( 'data-address' );
    const zip = target.getAttribute( 'data-zip' );
    const addressData = JSON.parse( target.getAttribute( 'data-object' ) );

    if ( address.includes( 'entries' ) ) {
      const value = address.replace( ' entries', '' );
      const suggestionValues = await fetchAddressSuggestions( address, value );

      return setSuggestions( suggestionValues );
    }

    refs.addressField.current.value = address.replace( ` ${ zip }`, '' );

    if ( refs.zipField ) {
      refs.zipField.current.value = zip;

      const { fieldName, fieldType, fieldValue } = JSON.parse( refs.zipField.current.getAttribute( 'data-payload' ) );

      const data = {
        formContext,
        fieldName,
        fieldType,
        fieldValue,
        autofilled: true
      };
      trackEvent( { action: 'fieldInputted', data, event } );
    }

    setSuggestions( [ ] );

    return { address, zip, addressData };
  };

  // ReCaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();

  /**
   * @function handleSubmit
   * @description Handle submission of form
   */
  const handleSubmit = async ( event, enterGdt = false ) => {
    event.preventDefault();

    if ( refs.honeyPotField.current.value !== '' ) {
      return;
    }

    const token = await executeRecaptcha();

    const addressObject = JSON.parse( event.target.getAttribute( 'data-address-object' ) );
    const userData = {
      address: {
        value: addressObject.value,
        addressKey: addressObject.addressKey,
        addressLine1: addressObject.addressLine1,
        addressLine2: addressObject.addressLine2,
        city: addressObject.city,
        stateProvince: addressObject.stateProvince,
        zipCode: addressObject.zipCode,
        zipCode4: addressObject.zipCode4,
        latitude: addressObject.latitude,
        longitude: addressObject.longitude,
        parsedAddress: addressObject.parsedAddress
      },
      plansDrop: true, // TODO: what do these do?
      showCTA: true, // TODO: what do these do?
      token
    };

    trackEvent( { action: 'formSubmitted', data: formContext, event } );
    addPageAction( 'cartEntrance', { method: 'ADDRESS FORM' } );

    // Set our cookie and redirect to the cart
    setCookie( 'frontierSiteDetailPredictive', JSON.stringify( userData ), 30 );
    persistData( 'frontierSiteDetailPredictive', JSON.stringify( userData ) );
    setTimeout( () => {
      // Check for persisted cart state and that addresses match
      if ( cartStore?.address?.install?.addressKey && cartStore?.address?.install?.addressKey !== addressObject.addressKey ) {
        submitDifferentAddress();
        return;
      }

      if ( enterGdt ) {
        window.location.href = `${ stripClickThrough( clickThrough ) }${ gdtVersion }/`;
      } else {
        window.location.href = clickThrough;
      }
    }, 100 );
  };

  return {
    fetchAddress, fetchAddressSuggestions, handleInput, executeRecaptcha, handleSubmit, handleClickEvent, handleKeyDownEvent, setSuggestionValue
  };
};

export default usePredictiveSearch;
